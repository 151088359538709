import { API } from 'aws-amplify'
import store from '@/store'
import ability from '../../../libs/acl/ability'

export default {
  abilityRequired: 'onboarding',
  '/company/onboarding': '/company/onboarding',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchItems(ctx, qvars) {
      return new Promise((resolve, reject) => {
        console.log('fetchItems')
        if (ability.can('read', 'onboarding')) {
          // console.log(qvars)
          const { forceRefresh } = qvars
          store.state.onboarding.fetchingList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()
          let totalItems = 0

          const resortItems = thUsers => {
            // sort the items
            let theSortBy = qvars.sortBy
            if (theSortBy === 'user') {
              theSortBy = 'last_name'
            }
            if (theSortBy === '') {
              theSortBy = 'last_name'
            }
            if (qvars.sortDesc) {
              thUsers.sort((a, b) => {
                console.log('sort anything desc')
                // console.log(a)
                // console.log(b)
                // console.log(theSortBy)
                return b[theSortBy].localeCompare(a[theSortBy])
              })
            } else {
              thUsers.sort((a, b) => {
                console.log('sort anything asc')
                // console.log(a)
                // console.log(b)
                // console.log(theSortBy)
                return a[theSortBy].localeCompare(b[theSortBy])
              })
            }
          }
          const paginateItems = item2 => {
            let theReturn = item2
            if (qvars.perPage > 0) {
              const start = (qvars.page - 1) * qvars.perPage
              const end = start + qvars.perPage
              theReturn = item2.slice(start, end)
            }
            return theReturn
          }
          const resortFilterItems = users => {
            const resultUsers = []
            // first apply filters
            users.forEach(item => {
              let addThisItem = true
              if (typeof qvars.role !== 'undefined' && qvars.role !== null && item.role !== qvars.role) {
                addThisItem = false
              }
              console.log('resortFilterUsers')
              console.log(qvars.onboarding)
              console.log(item)
              if (qvars.q !== null
                && !item.username.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.cognito_group.includes(qvars.q.toUpperCase())
                && !item.first_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.last_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.email.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.role.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (addThisItem) {
                resultUsers.push(item)
              }
            })
            totalItems = resultUsers.length
            // resort users
            resortItems(resultUsers)
            // Apply pagination and return
            return paginateItems(resultUsers)
          }

          // Check for caching
          if (
            forceRefresh
            || localStorage.getItem('onboarding') === null
            || parseInt(localStorage.getItem('onboarding_lastRefresh'), 10) + 60000 < timeDiff // cache for 1 hour
          ) {
            console.log('Refreshing Items')
            console.log(qvars)
            const apiName = 'APIGateway'
            const myInit = { response: true }

            API.get(apiName, '/user/onboardlist', myInit)
              .then(response => {
                const theResponse = response
                const items = response.data
                items.forEach(element => {
                  // eslint-disable-next-line no-param-reassign
                  element.fullName = `${element.first_name} ${element.last_name}`
                  // eslint-disable-next-line no-param-reassign
                  element.role = `${element.cognito_group}`

                  // if (element.is_active === true) {
                  //   // eslint-disable-next-line no-param-reassign
                  //   element.status = 'active'
                  // } else if (element.is_active === false) {
                  //   // eslint-disable-next-line no-param-reassign
                  //   element.status = 'disabled'
                  // }
                })
                // Store data in local storage
                localStorage.setItem('onboarding', JSON.stringify(items))
                localStorage.setItem('onboarding_lastRefresh', d1.getTime())
                theResponse.data.theItems = resortFilterItems(items)
                theResponse.data.totalItems = totalItems
                console.log(theResponse)
                store.state.onboarding.fetchingList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.onboarding.fetchingList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Data')
            const theResponse = { data: {} }
            const itemsData = JSON.parse(localStorage.getItem('onboarding'))
            theResponse.data.theItems = resortFilterItems(itemsData)
            theResponse.data.totalItems = totalItems
            // console.log(theResponse)
            store.state.onboarding.fetchingList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchItem(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'onboarding')) {
          const apiName = 'APIGateway'
          const path = `/user/${encodeURIComponent(qvars.username)}/onboarding`
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              // console.log(response)
              const theResponse = response
              theResponse.data = response.data
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    addItem(ctx, theData) {
      return new Promise((resolve, reject) => {
        console.log('Add Item')
        if (ability.can('create', 'onboarding') || ability.can('update', 'onboarding')) {
          const apiName = 'APIGateway'

          API.post(apiName, `/user/${theData.username}/onboarding`, { body: theData })
            .then(response => {
              // Add new template to the local storage to avoid call to backend
              // const newReports = JSON.parse(localStorage.getItem('onboarding'))
              // newReports.push(theData)
              // localStorage.setItem('onboarding', JSON.stringify(newReports))
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          console.error('Not Allowed')
          reject()
        }
      })
    },
    updateItem(ctx, theData) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'onboarding')) {
          const apiName = 'APIGateway'

          API.patch(apiName, `/user/${theData.username}/onboarding`, { body: theData })
            .then(response => {
              // Update template in the local storage to avoid call to backend
              // const oldReports = JSON.parse(localStorage.getItem('onboarding'))
              // const newReports = []
              // if (typeof oldReports !== 'undefined' && oldReports !== null && oldReports.length > 0) {
              //   oldReports.forEach(item => {
              //     if (item.SK === theData.SK) {
              //       newReports.push(theData)
              //     } else {
              //       newReports.push(item)
              //     }
              //   })
              //   localStorage.setItem('onboarding', JSON.stringify(newReports))
              // }
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          console.error('Not Allowed')
          reject()
        }
      })
    },
    docusign(ctx, theUsername) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'onboarding')) {
          const apiName = 'APIGateway'

          API.get(apiName, `/user/${theUsername}/onboarding/docusign`)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          console.error('Not Allowed')
          reject()
        }
      })
    },
  },
}
