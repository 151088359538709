import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useClientSitesList(clientname) {
  console.log('useClientSitesList clientname:')
  console.log(clientname)
  // Use toast
  const toast = useToast()

  const refClientSitesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'sitename', sortable: true },
    { key: 'client_display_name', sortable: true },
  ]
  const perPage = ref(10)
  const totalClientSites = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100, 200]
  const searchQuery = ref('')
  const sortBy = ref('client_display_name')
  const isBusy = ref(false)
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  const clientSitesList = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refClientSitesListTable.value ? refClientSitesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClientSites.value,
    }
  })

  const refetchData = () => new Promise(resolve => {
    const res = true
    refClientSitesListTable.value.refresh().then(
      resolve(res),
    )
  })

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchClientSites = (ctx, callback) => {
    console.log('fetchClientSites')
    if (ability.can('read', 'clients')) {
      const qvars = {
        forceRefresh: store.state.clients.fetchingClientSitesList,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        clientname,
      }
      let appURL = 'app-client/fetchAllClientSites'
      console.log(qvars)
      if (clientname !== '' && clientname !== 'Cancom') {
        appURL = 'app-client/fetchClientSites'
      }
      store
        .dispatch(appURL, qvars)
        .then(response => {
          console.log('fetchAllClientSites Response')
          console.log(response)
          const { clientSites } = response.data
          try {
            clientSites.forEach((item, index) => {
              clientSites[index].UID = `${clientSites[index].client_display_name}-${clientSites[index].sitename}`
              clientSites[index].authorized = false
            })
          } catch (error) {
            console.log('Not able to add authorized attribute')
          }
          callback(clientSites)
          totalClientSites.value = response.data.totalClientSites
          clientSitesList.value = response.data.clientSites
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching client sites list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveClientSitesStatusVariant = authorized => {
    if (authorized === true) return 'success'
    if (authorized === false) return 'secondary'
    return 'success'
  }

  return {
    fetchClientSites,
    tableColumns,
    perPage,
    currentPage,
    totalClientSites,
    clientSitesList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refClientSitesListTable,

    resolveClientSitesStatusVariant,
    refetchData,

    // Extra Filters
    statusFilter,
  }
}
