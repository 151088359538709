<template>
  <b-card
    v-if="$can('update', 'users')"
    no-body
  >
    <b-card-header class="pb-50">
      <h4>
        Authorized Client Sites
      </h4>
    </b-card-header>
    <b-card-header class="pb-50 ml-2 border-top-secondary">
      <h5>
        All Client Sites
      </h5>
    </b-card-header>
    <b-card-body>
      <!-- Per Page -->
      <b-row class="mb-2 mt-1">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label> -->
          <b-button
            class="ml-2"
            variant="primary"
            :disabled="this.$store.state.clients.fetchingClientSitesList"
            @click="refreshClientSitesList"
          >
            <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="selectAllRows"
          >
            <feather-icon icon="CheckSquareIcon" /><span class="text-nowrap ml-1">Select All</span>
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="clearSelected"
          >
            <feather-icon icon="MinusSquareIcon" /><span class="text-nowrap ml-1">Clear</span>
          </b-button>
        </b-col>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2 mt-1">
        <b-col
          cols="12"
          md="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            class="ml-2"
            label="Selection mode:"
          >
            <v-select
              v-model="selectMode"
              :options="modes"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refClientSitesListTable"
            selectable
            hover
            responsive
            show-empty
            empty-text=""
            :items="fetchClientSites"
            :fields="tableColumns"
            primary-key="UID"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy.sync="isBusy"
            :select-mode="selectMode"
            @row-selected="onRowSelected"
            @row-unselected="onRowUnselected"
          >
            <!-- Column: Site Name-->
            <template #cell(sitename)="data">
              <div class="text-nowrap">
                {{ data.item.sitename }}
              </div>
            </template>

            <!-- Column: client_display_name -->
            <template #cell(client_display_name)="data">
              <div class="text-nowrap">
                {{ data.item.client_display_name }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- Pagination -->
      <b-row class="mb-2 mt-1">
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalClientSites"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
      <!-- Update Button -->
      <b-row class="mt-3 mb-1">
        <b-col
          md="3"
        >
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            block
            @click="addAuthClients"
          >
            Add To Below List
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body>
      <b-card-header class="pb-50 border-top-secondary">
        <h5>
          Selected Client Sites
        </h5>
      </b-card-header>
      <b-row class="mb-2 mt-1">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            class="ml-2"
            variant="primary"
            @click="selectAllRowsAuthList"
          >
            <feather-icon icon="CheckSquareIcon" /><span class="text-nowrap ml-1">Select All</span>
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="clearSelectedAuthList"
          >
            <feather-icon icon="MinusSquareIcon" /><span class="text-nowrap ml-1">Clear</span>
          </b-button>
        </b-col>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQueryAuthList"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2 mt-1">
        <b-col
          cols="12"
          md="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            class="ml-2"
            label="Selection mode:"
          >
            <v-select
              v-model="selectModeAuthList"
              label="title"
              :options="modes"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refClientSitesAuthListTable"
            selectable
            hover
            responsive
            show-empty
            empty-text=""
            :items="authorizedClientsShow"
            :fields="tableColumnsAuthList"
            primary-key="UID"
            :sort-by.sync="sortByAuthList"
            :sort-desc.sync="isSortDirDescAuthList"
            :busy.sync="isBusyAuthList"
            :select-mode="selectModeAuthList"
            @row-selected="onRowSelectedAuthList"
            @row-unselected="onRowUnselectedAuthList"
          >
            <!-- Column: Site Name-->
            <template #cell(sitename)="data">
              <div class="text-nowrap">
                {{ data.item.sitename }}
              </div>
            </template>

            <!-- Column: clientname -->
            <template #cell(clientname)="data">
              <div class="text-nowrap">
                {{ data.item.clientname }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- Pagination -->
      <b-row class="mb-2 mt-1">
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMetaAuthList.from }} to {{ dataMetaAuthList.to }} of {{ dataMetaAuthList.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPageAuthList"
            :total-rows="totalClientSitesAuthList"
            :per-page="perPageAuthList"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
      <!-- Remove Button -->
      <b-row class="mt-3 mb-1">
        <b-col
          md="3"
        >
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            block
            @click="removeSelectedAuthList"
          >
            Remove Selected
          </b-button>
        </b-col>
      </b-row>
      <!-- Save Button -->
      <b-row class="mt-3 mb-1">
        <b-col
          md="3"
        >
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            block
            @click="submitAuthClients"
          >
            Save Authorized Client Sites
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BPagination, BFormInput, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BTable, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import { ref, watch, computed } from '@vue/composition-api'
import useClientSitesList from './useClientSitesList'
// import useClientSitesListAuthList from './useClientSitesListAuthList'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BPagination,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BTable,
    vSelect,
    BFormGroup,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    clientSitesListData: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      perPage: 25,
      perPageOptions: [25, 50, 100, 200],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      unSelectedRows: [],
      selectedRows: [],
      isListRefreshing: false,
      modes: ['multi', 'single', 'range'],
      selectMode: 'multi',
      selectModeAuthList: 'multi',
      perPageAuthList: 25,
      totalRowsAuthList: 1,
      currentPageAuthList: 1,
      sortByAuthList: '',
      sortDescAuthList: false,
      sortDirectionAuthList: 'asc',
      unSelectedRowsAuthList: [],
      selectedRowsAuthList: [],
    }
  },
  computed: {
    isFetchingClientList() {
      console.log('isFetchingClientList')
      console.log(this.$store.getters.getFetchingClientSitesList)
      return this.$store.getters.getFetchingClientSitesList
    },
  },
  setup(props) {
    console.log('props')
    console.log(props)
    let theClientName = ''
    if ((props.userData.cognito_group === 'Client_Viewer' || props.userData.cognito_group === 'Client_Admin') && typeof props.userData.client_name !== 'undefined' && props.userData.client_name !== '' && props.userData.client_name !== null) {
      console.log('theClientName')
      console.log(theClientName)
      theClientName = props.userData.client_name
      console.log(theClientName)
    }
    const {
      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalClientSites,
      clientSitesList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientSitesListTable,
      refetchData,
    } = useClientSitesList(theClientName)

    // const {
    //   authorizedClients,
    //   fetchClientSitesAuthList,
    //   tableColumnsAuthList,
    //   perPageAuthList,
    //   currentPageAuthList,
    //   totalClientSitesAuthList,
    //   clientSitesListAuthList,
    //   dataMetaAuthList,
    //   perPageOptionsAuthList,
    //   searchQueryAuthList,
    //   sortByAuthList,
    //   isBusyAuthList,
    //   isSortDirDescAuthList,
    //   refClientSitesListTableAuthList,
    //   refetchDataAuthList,
    // } = useClientSitesListAuthList()

    const refClientSitesAuthListTable = ref(null)

    // Table Handlers
    const tableColumnsAuthList = [
      { key: 'sitename', sortable: true },
      { key: 'clientname', sortable: true },
    ]
    const perPageAuthList = ref(10)
    const totalClientSitesAuthList = ref(0)
    const currentPageAuthList = ref(1)
    const perPageOptionsAuthList = [10, 25, 50, 100, 200]
    const searchQueryAuthList = ref(null)
    const sortByAuthList = ref('clientname')
    const isBusyAuthList = ref(false)
    const isSortDirDescAuthList = ref(false)
    const clientSitesListAuthList = ref(null)
    const authorizedClients = ref(null)
    const authorizedClientsShow = ref(null)

    const dataMetaAuthList = computed(() => {
      const localItemsCount = refClientSitesAuthListTable.value ? refClientSitesAuthListTable.value.localItems.length : 0
      return {
        from: perPageAuthList.value * (currentPageAuthList.value - 1) + (localItemsCount ? 1 : 0),
        to: perPageAuthList.value * (currentPageAuthList.value - 1) + localItemsCount,
        of: totalClientSitesAuthList.value,
      }
    })

    // const updateClientSitesListData = e => {
    //   console.log('updateClientSitesListData')
    //   console.log(e)
    //   e.forEach(item => {
    //     props.clientSitesListData.push(item)
    //   })
    // }
    function fetchUserAuthClientSites(clients) {
      console.log('fetchUserAuthClientSites')
      console.log(clients)
      const qvars = {
        q: searchQueryAuthList.value,
        perPage: perPageAuthList.value,
        page: currentPageAuthList.value,
        sortBy: sortByAuthList.value,
        sortDesc: isSortDirDescAuthList.value,
        authClients: clients,
      }
      console.log('fetchUserAuthClientSites')
      console.log(qvars)
      let totalItems = 0
      const resultClients = []

      const resortUsers = items => {
        // sort the items
        const theSortBy = qvars.sortBy
        if (qvars.sortDesc) {
          items.sort((a, b) => {
            console.log('sort anything desc')
            // console.log(a)
            // console.log(b)
            // console.log(theSortBy)
            return b[theSortBy].localeCompare(a[theSortBy])
          })
        } else {
          items.sort((a, b) => {
            console.log('sort anything asc')
            // console.log(a)
            // console.log(b)
            // console.log(theSortBy)
            return a[theSortBy].localeCompare(b[theSortBy])
          })
        }
      }
      const paginateUsers = items2 => {
        const start = (qvars.page - 1) * qvars.perPage
        const end = start + qvars.perPage
        return items2.slice(start, end)
      }
      const resortFilterUsers = authClients => {
        // filter by search term
        console.log(qvars.q)
        let addThisItem = true
        authClients.forEach(item => {
          addThisItem = true
          if (qvars.q !== null
            && item.clientname.toUpperCase().includes(qvars.q.toUpperCase()) === false
            && item.sitename.toUpperCase().includes(qvars.q.toUpperCase()) === false) {
            addThisItem = false
          }
          console.log(addThisItem)
          if (addThisItem) {
            resultClients.push(item)
          }
        })
        totalItems = resultClients.length
        // resort users
        resortUsers(resultClients)
        // Apply pagination and return
        return paginateUsers(resultClients)
      }
      // const theResponse = {}
      // theResponse.data = {}
      // theResponse.data.clientSites = resortFilterUsers(qvars.authClients)
      // theResponse.data.totalClientSites = totalItems
      authorizedClientsShow.value = resortFilterUsers(qvars.authClients)
      totalClientSitesAuthList.value = totalItems
    }

    // watch(clientSitesList, updateClientSitesListData)
    const watcher = watch([currentPageAuthList, perPageAuthList, searchQueryAuthList, authorizedClients], () => {
      console.log('triggered watch')
      fetchUserAuthClientSites(authorizedClients.value)
    })

    return {
      fetchClientSites,
      tableColumns,
      perPage,
      currentPage,
      totalClientSites,
      clientSitesList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refClientSitesListTable,
      refetchData,

      authorizedClients,
      authorizedClientsShow,
      // fetchClientSitesAuthList,
      tableColumnsAuthList,
      perPageAuthList,
      currentPageAuthList,
      totalClientSitesAuthList,
      clientSitesListAuthList,
      dataMetaAuthList,
      perPageOptionsAuthList,
      searchQueryAuthList,
      sortByAuthList,
      isBusyAuthList,
      isSortDirDescAuthList,
      // refClientSitesListTableAuthList,
      // refetchDataAuthList,
      watcher,
    }
  },
  watch: {
    authorizedClientsShow: {
      handler(newVal) {
        console.log('authorizedClientsShow')
        console.log(newVal)
        if (this.$refs.refClientSitesAuthListTable) {
          this.$refs.refClientSitesAuthListTable.refresh()
        }
      },
      deep: true,
    },
  },
  created() {
    this.authorizedClients = this.userData.authorized_clients
  },
  methods: {
    refreshClientSitesList() {
      const prevAuthorizedClients = JSON.parse(JSON.stringify(this.userData.authorized_clients))
      this.$store.state.clients.fetchingClientSitesList = true
      this.refetchData().then(
        this.userData.authorized_clients = prevAuthorizedClients,
        this.highlightAuthClients(),
      )
    },
    onRowSelected(items) {
      if (!this.isListRefreshing) {
        console.log('onRowSelected')
        console.log(items)
        // this.selectedRows.forEach(row => {
        //   let foundMatch = false
        //   items.forEach(item => {
        //     if (item.sitename === row.sitename) {
        //       foundMatch = true
        //     }
        //   })
        //   if (foundMatch !== true) {
        //     // eslint-disable-next-line no-param-reassign
        //     row.authorized = false
        //   }
        // })
        // this.selectedRows = items
        // const tmp = []
        // items.forEach((item, index) => {
        //   console.log(index)
        //   console.log(this.$refs.refClientSitesListTable.selectedRows)
        //   tmp.push({ clientname: item.clientname, sitename: item.sitename, authorized: true })
        // })
        // console.log(tmp)
        // this.userData.authorized_clients = JSON.parse(JSON.stringify(tmp))
        // console.log(this.userData.authorized_clients)
        // this.highlightAuthClients()
      }
    },
    onRowUnselected(items) {
      if (!this.isListRefreshing) {
        console.log('onRowUnselected')
        console.log(items)
        // this.unSelectedRows = items
        // this.unSelectedRows.forEach(row => {
        //   // eslint-disable-next-line no-param-reassign
        //   row.authorized = false // For example, increment the age
        // })
      }
    },
    selectAllRows() {
      console.log('selectAllRows')
      this.$refs.refClientSitesListTable.selectAllRows()
    },
    clearSelected() {
      console.log('clearSelected')
      this.$refs.refClientSitesListTable.clearSelected()
    },
    highlightAuthClients() {
      console.log('highlightAuthClients')
      console.log(this.userData.authorized_clients)
      // Highlight the original Authorized Clients
      // if (typeof this.userData.authorized_clients !== 'undefined' && this.userData.authorized_clients.length > 0) {
      //   this.userData.authorized_clients.forEach(item => {
      //     console.log(item)
      //     this.clientSitesList.forEach((site, index) => {
      //       console.log(site)
      //       if (item.clientname === site.clientname && item.sitename === site.sitename) {
      //         // this.userData.authorized_clients[index].authorized = true
      //         this.selectRow(index)
      //       }
      //     })
      //   })
      // }
      this.isListRefreshing = false
    },
    selectRow(rowNum) {
      console.log(`selectRow ${rowNum}`)
      this.$refs.refClientSitesListTable.selectRow(rowNum)
    },
    addAuthClients() {
      console.log('addAuthClients')
      console.log(this.$refs.refClientSitesListTable.selectedRows)
      const selectedItems = this.$refs.refClientSitesListTable.selectedRows
      const allItems = this.$refs.refClientSitesListTable.computedItems
      let foundDup = false
      selectedItems.forEach((item, index) => {
        if (item) {
          foundDup = false
          this.userData.authorized_clients.forEach(item2 => {
            if (allItems[index].clientname === item2.clientname && allItems[index].sitename === item2.sitename) {
              foundDup = true
            }
          })
          if (!foundDup) {
            this.userData.authorized_clients.push({ clientname: allItems[index].clientname, sitename: allItems[index].sitename })
          }
        }
      })
      console.log(this.userData.authorized_clients)
      this.clearSelected()
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'List Updated - Please Save',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      this.authorizedClients = this.userData.authorized_clients
      this.authorizedClientsShow = this.userData.authorized_clients

      setTimeout(() => {
        this.searchQueryAuthList = null
      }, 1000)
    },
    // Auth List
    onRowSelectedAuthList(items) {
      console.log('onRowSelectedAuthList')
      console.log(items)
      // this.highlightAuthClients()
    },
    onRowUnselectedAuthList(items) {
      console.log('onRowUnselectedAuthList')
      console.log(items)
    },
    selectAllRowsAuthList() {
      console.log('selectAllRowsAuthList')
      this.$refs.refClientSitesAuthListTable.selectAllRows()
    },
    clearSelectedAuthList() {
      console.log('clearSelectedAuthList')
      this.$refs.refClientSitesAuthListTable.clearSelected()
    },
    selectRowAuthList(rowNum) {
      console.log(`selectRow ${rowNum}`)
      this.$refs.refClientSitesAuthListTable.selectRow(rowNum)
    },
    removeSelectedAuthList() {
      console.log('removeSelectedAuthList')
      const items = this.userData.authorized_clients
      const selectedItems = this.$refs.refClientSitesAuthListTable.selectedRows
      console.log(selectedItems)
      const allItems = this.$refs.refClientSitesAuthListTable.computedItems
      const tmp = []
      let addItem = false
      items.forEach(item => {
        addItem = true
        selectedItems.forEach((item2, index2) => {
          console.log('TEST')
          console.log(`${item.clientname} ${item.sitename}`)
          console.log(`${allItems[index2].clientname} ${allItems[index2].sitename}`)
          if (item2 === true && (item.clientname === allItems[index2].clientname && item.sitename === allItems[index2].sitename)) {
            addItem = false
          }
        })
        console.log(addItem)
        if (addItem) {
          console.log('Added')
          tmp.push({ clientname: item.clientname, sitename: item.sitename })
        }
      })
      console.log(tmp)
      this.userData.authorized_clients = JSON.parse(JSON.stringify(tmp))
      console.log(this.userData.authorized_clients)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'List Updated - Please Save',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      this.authorizedClients = this.userData.authorized_clients
    },
    submitAuthClients() {
      if (ability.can('update', 'users')) {
        console.log(this.userData)
        // Create a clean dataset with only the data being updated
        const cleanUserData = {
          username: this.userData.username,
          authorized_clients: this.userData.authorized_clients,
          client_name: this.userData.client_name,
        }
        console.log(cleanUserData)
        // Call backend
        store.dispatch('app-user/updateUser', cleanUserData)
          .then(response => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'User Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating user information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
  .b-table-row-selected > td {
    background-color: #8b0202 !important;
    color: azure!important;
  }
  tr:hover {
    color: azure!important;
  }
</style>
