<template>
  <b-card
    v-if="$can('update', 'users') && showPermissions"
    no-body
  >
    <b-card-header class="pb-50">
      <h5>
        Permissions
      </h5>
    </b-card-header>
    <b-card-body v-if="showAbilitiesTemplatesList">
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Permissions Template</label>
          <v-select
            v-model="selectedTemplate"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="abilitiesTemplatesList"
            class="w-100"
            @input="(val) => selectTemplate(val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="permissionsData"
      class="mb-0"
    >

      <template #cell(module)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          :id="data.value.name"
          v-model="selected"
          :name="data.value.name"
          :checked="data.value.value"
          :value="data.value.checkedvalue"
          :unchecked-value="data.value.uncheckedvalue"
          :disabled="data.value.disabledState"
          @change="updatePermissions(true)"
        />
      </template>

    </b-table>
    <b-card-body>
      <!-- <b-row>
        <b-col
          md="12"
        >
          To save permissions you must use the Save Changes button above.
        </b-col>
      </b-row> -->
      <b-row>
        <b-col
          md="3"
        >
          <b-button
            v-if="$can('update', 'users')"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            block
            @click="submitPermissions"
          >
            Update Permissions
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardHeader, BFormCheckbox, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BTable, BCardBody, BFormCheckbox, vSelect, BCardHeader, BRow, BCol, BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      theUserData: this.userData,
      permissionsData: [],
      abilitiesTemplates: [],
      abilitiesTemplatesList: [],
      showAbilitiesTemplatesList: true,
      selectedTemplate: '',
      showPermissions: false,
    }
  },
  setup() {
    const loggedInUserData = getUserData()

    return {
      loggedInUserData,
    }
  },
  created() {
    console.log('created')
    this.setPermissionsData(this.theUserData.abilities)
    this.abilitiesTemplatesList = []

    // get the Abilities Templates
    const setAbilitiesTemplatesList = abilitiesTemplates => {
      abilitiesTemplates.forEach(item => {
        this.abilitiesTemplatesList.push({ label: item.templatename, value: item.templatename })
      })
      console.log('abilitiesTemplatesList')
      console.log(this.abilitiesTemplatesList)
    }
    if (this.loggedInUserData.group === 'Client_Admin' || this.loggedInUserData.group === 'Client_Viewer') {
      this.showAbilitiesTemplatesList = false
    }
    if (!ability.can('read', 'users')) {
      this.showAbilitiesTemplatesList = false
    }

    this.abilitiesTemplates = JSON.parse(localStorage.getItem('abilities_templates'))
    if (this.abilitiesTemplates === null) {
      if (ability.can('read', 'users')) {
        const qvars = {
          forceRefresh: true,
          q: '',
          perPage: 10,
          page: 1,
          sortBy: 'templatename',
          sortDesc: true,
        }
        console.log(qvars)
        store
          .dispatch('app-abilities-templates/fetchTemplates', qvars)
          .then(() => {
            this.abilitiesTemplates = JSON.parse(localStorage.getItem('abilities_templates'))
            setAbilitiesTemplatesList(this.abilitiesTemplates)
          })
          .catch(error => {
            console.log(error)
          })
      }
    } else {
      setAbilitiesTemplatesList(this.abilitiesTemplates)
    }
  },
  methods: {
    setPermissionsData(data) {
      // console.log('this.userData')
      // console.log(this.userData)
      const abilitiesOptions = store.state.appSettings.abilities_options
      // Remove Developer abilities if in staging or production
      if (process.env.VUE_APP_ENVIRONMENT_NAME === 'staging' || process.env.VUE_APP_ENVIRONMENT_NAME === 'production') {
        abilitiesOptions.forEach((item, index) => {
          if (item.subject === 'developer') {
            abilitiesOptions.splice(index, 1)
          }
        })
      }
      let tmp = {}
      this.permissionsData = []
      this.selected = []

      abilitiesOptions.forEach(item => {
        if (item.managedby.includes(this.loggedInUserData.group)) {
          this.showPermissions = true
          tmp = { module: item.title }
          tmp.read = {
            name: `${item.subject}-read`, value: `${item.subject}-read-false`, checkedvalue: `${item.subject}-read-true`, uncheckedvalue: `${item.subject}-read-false`, disabledState: false,
          }
          // Restricting client create to only Management
          if (item.subject === 'clients' && this.userData.role === 'Management') {
            tmp.create = {
              name: `${item.subject}-create`, value: `${item.subject}-create-false`, checkedvalue: `${item.subject}-create-true`, uncheckedvalue: `${item.subject}-create-false`, disabledState: false,
            }
          } else if (item.subject === 'clients') {
            tmp.create = {
              name: `${item.subject}-create`, value: `${item.subject}-create-false`, checkedvalue: `${item.subject}-create-true`, uncheckedvalue: `${item.subject}-create-false`, disabledState: true,
            }
          } else {
            tmp.create = {
              name: `${item.subject}-create`, value: `${item.subject}-create-false`, checkedvalue: `${item.subject}-create-true`, uncheckedvalue: `${item.subject}-create-false`, disabledState: false,
            }
          }

          tmp.update = {
            name: `${item.subject}-update`, value: `${item.subject}-update-false`, checkedvalue: `${item.subject}-update-true`, uncheckedvalue: `${item.subject}-update-false`, disabledState: false,
          }
          // Restricting client and cameras delete to only Management
          if ((item.subject === 'cameras' || item.subject === 'clients') && this.userData.role === 'Management') {
            tmp.delete = {
              name: `${item.subject}-delete`, value: `${item.subject}-delete-false`, checkedvalue: `${item.subject}-delete-true`, uncheckedvalue: `${item.subject}-delete-false`, disabledState: false,
            }
          } else if (item.subject === 'cameras' || item.subject === 'clients') {
            tmp.delete = {
              name: `${item.subject}-delete`, value: `${item.subject}-delete-false`, checkedvalue: `${item.subject}-delete-true`, uncheckedvalue: `${item.subject}-delete-false`, disabledState: true,
            }
          } else {
            tmp.delete = {
              name: `${item.subject}-delete`, value: `${item.subject}-delete-false`, checkedvalue: `${item.subject}-delete-true`, uncheckedvalue: `${item.subject}-delete-false`, disabledState: false,
            }
          }
          // Populate with current users permissions
          if (typeof data !== 'undefined' && data.length > 0) {
            data.forEach(item2 => {
              if (item2.subject === item.subject) {
                item2.actions.forEach(item3 => {
                  if (item3.action === 'read') {
                    tmp.read = {
                      name: `${item.subject}-read`, value: `${item.subject}-read-true`, checkedvalue: `${item.subject}-read-true`, uncheckedvalue: `${item.subject}-read-false`, disabledState: false,
                    }
                    this.selected.push(`${item.subject}-read-true`)
                  } else if (item3.action === 'create') {
                    tmp.create = {
                      name: `${item.subject}-create`, value: `${item.subject}-create-true`, checkedvalue: `${item.subject}-create-true`, uncheckedvalue: `${item.subject}-create-false`, disabledState: false,
                    }
                    this.selected.push(`${item.subject}-create-true`)
                  } else if (item3.action === 'update') {
                    tmp.update = {
                      name: `${item.subject}-update`, value: `${item.subject}-update-true`, checkedvalue: `${item.subject}-update-true`, uncheckedvalue: `${item.subject}-update-false`, disabledState: false,
                    }
                    this.selected.push(`${item.subject}-update-true`)
                  } else if (item3.action === 'delete') {
                    tmp.delete = {
                      name: `${item.subject}-delete`, value: `${item.subject}-delete-true`, checkedvalue: `${item.subject}-delete-true`, uncheckedvalue: `${item.subject}-delete-false`, disabledState: false,
                    }
                    this.selected.push(`${item.subject}-delete-true`)
                  }
                })
              }
            })
          }
          // Filter out non-applicable permissions for client viewer and client admins
          if ((this.theUserData.cognito_group === 'Client_Admin' || this.theUserData.cognito_group === 'Client_Viewer')
            && item.internal === true) {
            // Do not add this permission
          } else if (this.theUserData.cognito_group === 'Client_Admin' || this.theUserData.cognito_group === 'Client_Viewer') {
            // Filter out permissions if Client User and add permission
            // No Delete for any
            tmp.delete = {
              name: `${item.subject}-delete`, value: `${item.subject}-delete-false`, checkedvalue: `${item.subject}-delete-true`, uncheckedvalue: `${item.subject}-delete-false`, disabledState: true,
            }
            // Restrict Create
            if (item.subject !== 'users' && item.subject !== 'parkingpermit') {
              tmp.create = {
                name: `${item.subject}-create`, value: `${item.subject}-create-false`, checkedvalue: `${item.subject}-create-true`, uncheckedvalue: `${item.subject}-create-false`, disabledState: true,
              }
            }
            // Restrict Update
            if (item.subject === 'users' || item.subject === 'clients' || item.subject === 'parkingpermit') {
              tmp.update = {
                name: `${item.subject}-update`, value: `${item.subject}-update-false`, checkedvalue: `${item.subject}-update-true`, uncheckedvalue: `${item.subject}-update-false`, disabledState: false,
              }
            } else {
              tmp.update = {
                name: `${item.subject}-update`, value: `${item.subject}-update-false`, checkedvalue: `${item.subject}-update-true`, uncheckedvalue: `${item.subject}-update-false`, disabledState: true,
              }
            }
            this.permissionsData.push(tmp)
          } else {
            this.permissionsData.push(tmp)
          }
          this.updatePermissions(false)
        }
      })
      // console.log('this.permissionsData')
      // console.log(this.permissionsData)
    },
    updatePermissions(resetDropDown) {
      if (resetDropDown) {
        this.selectedTemplate = ''
      }
      const abilitiesOptions = store.state.appSettings.abilities_options
      // Remove Developer abilities if in staging or production
      if (process.env.VUE_APP_ENVIRONMENT_NAME === 'staging' || process.env.VUE_APP_ENVIRONMENT_NAME === 'production') {
        abilitiesOptions.forEach((item, index) => {
          if (item.subject === 'developer') {
            abilitiesOptions.splice(index, 1)
          }
        })
      }
      const abilities = []
      let foundActions = false
      let tmp = {}
      // sort the list of selected abilities and loop through to format in final JSON format before saving
      this.selected.sort()
      abilitiesOptions.forEach(item => {
        tmp = { subject: item.subject, actions: [] }
        foundActions = false
        this.selected.forEach(item1 => {
          const parts = item1.split('-')
          if (parts[0] === item.subject) {
            tmp.actions.push({ action: parts[1] })
            foundActions = true
          }
        })
        if (foundActions) {
          abilities.push(tmp)
        }
      })
      // Update the user data before sending
      this.theUserData.abilities = abilities
      console.log(this.theUserData)
    },
    selectTemplate(val) {
      if (val !== null && val.value !== null) {
        let selectedTemplate = {}
        this.abilitiesTemplates.forEach(item => {
          if (item.templatename === val.value) {
            selectedTemplate = item
          }
        })
        this.setPermissionsData(selectedTemplate.templateJSON)
      }
    },
    submitPermissions() {
      console.log('submitPermissions')
      if (ability.can('update', 'users')) {
        console.log(this.theUserData)
        const cleanUserData = {
          username: this.theUserData.username,
          abilities: this.theUserData.abilities,
          client_name: this.theUserData.client_name,
        }
        console.log(cleanUserData)
        store.dispatch('app-user/updateUser', cleanUserData)
          .then(response => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'User Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error adding user information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
  },
}
</script>

<style>

</style>
